<template>
  <div class="home-entry">
    <!-- <p class="talke">智慧病房护理信息对讲系统</p> -->
    <div class="talke">
      <div class="top_left">
        <p>智慧病房护理信息对讲系统</p>
      </div>
      <div class="top_right">
        <RouterLink to="/nursing" class="xtx-more">
          <span>查看更多</span>
          <i class="iconfont icon-angle-right"></i>
        </RouterLink>
      </div>
    </div>
    <div @click="gotalke" class="container1 container_com"></div>
    <div class="talke">
      <div class="top_left">
        <p>智慧病房信息发布系统</p>
      </div>
      <div class="top_right">
        <RouterLink to="/message" class="xtx-more">
          <span>查看更多</span>
          <i class="iconfont icon-angle-right"></i>
        </RouterLink>
      </div>
    </div>
    <div @click="messageSys" class="container2 container_com"></div>
    <div class="talke">
      <div class="top_left">
        <p>智慧病房排队叫号系统</p>
      </div>
      <div class="top_right">
        <RouterLink to="/lineUp" class="xtx-more">
          <span>查看更多</span>
          <i class="iconfont icon-angle-right"></i>
        </RouterLink>
      </div>
    </div>
    <div @click="jiaohaoSys" class="container3 container_com"></div>
  </div>
</template>
<script>
import router from '@/router'

export default {
  name: 'HomePage',
  setup () {
    const gotalke = () => {
      router.push('/nursing')
    }
    const messageSys = () => {
      router.push('/message')
    }
    const jiaohaoSys = () => {
      router.push('/lineUp')
    }
    return { gotalke, messageSys, jiaohaoSys }
  }
}
</script>
<style scoped lang="less">
.xtx-more {
  margin-bottom: 2px;
  span {
    font-size: 16px;
    vertical-align: middle;
    margin-right: 4px;
    color: #999;
  }
  i {
    font-size: 14px;
    vertical-align: middle;
    position: relative;
    top: 2px;
    color: #ccc;
  }
  &:hover {
    span,i {
      color:#0d4596;
    }
  }
}
.container_com{
  width: 100%;
  height: 500px;
}
.talke{
  width: 1024px;
  height: 90px;
  margin: 0 auto;
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  display: flex;
  .top_left{
    flex: 1.1;
    padding-top: 20px;
  }
  .top_right{
    padding-top: 20px;
    padding-left: 20px;
    flex: 0.1;
  }
}
.talke1{
  margin-top: 40px;
  margin-bottom: 10px;
  font-size: 40px;
  font-weight: bold;
  text-align: center;
}
.talke2{
  margin-top: 40px;
  margin-bottom: 10px;
  font-size: 40px;
  font-weight: bold;
  text-align: center;
}
.container1{
  background: url('../../assets/images/banner/banner1.png') no-repeat center / contain;
}
.container2{
  background: url('../../assets/images/banner/plan3.png') no-repeat center / contain;
}
.container3{
  background: url('../../assets/images/banner/plan4.png') no-repeat center / contain;
}

</style>
